<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="asmt-con">
      <div style="display: flex">
        <div class="principle-dd">
          <label for="pillar" class="form-label page-heading-dropdown lable-text">Pillar</label>
          <b-form-input class="form-control select-text select-text-pd text-class" v-model="pillarName" disabled>
          </b-form-input>
        </div>
        <div class="principle-dd" style="padding-left: 0.5rem">
          <label for="pillar" class="form-label page-heading-dropdown lable-text">Principle</label>
          <b-form-input class="form-control select-text select-text-pd text-class" v-model="principleName"
            disabled></b-form-input>
        </div>
      </div>

      <div class="level-con">
        <div class="level-border">
          <b-form-radio-group id="radio-group-1" v-model="targetLevel" name="radio-options"
            style="display: inline-flex; width: 100%">

            <div class="page-heading-tab level-info level-br live-level" style="border-radius: 4px 0 0 4px" id="Level0"
              :class="{ cursorNotAllowed: levelId != 0 }" :disabled="levelId != 0">
              <label class="container">
                Level 0
                <input type="radio" class="cursorpointer" name="some-radios" value="0" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level1"
              :class="{ cursorNotAllowed: levelId != 1 }" :disabled="levelId != 1">
              <label class="container">
                Level 1
                <input type="radio" class="cursorpointer" name="some-radios" value="1" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level2"
              :class="{ cursorNotAllowed: levelId != 2 }" :disabled="levelId != 2">
              <label class="container">
                Level 2
                <input type="radio" class="cursorpointer" name="some-radios" value="2" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level3"
              :class="{ cursorNotAllowed: levelId != 3 }" :disabled="levelId != 3">
              <label class="container">
                Level 3
                <input type="radio" class="cursorpointer" name="some-radios" value="3" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level4"
              :class="{ cursorNotAllowed: levelId != 4 }" :disabled="levelId != 4">
              <label class="container">
                Level 4
                <input type="radio" class="cursorpointer" name="some-radios" value="4" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info live-level" id="Level5" :class="{ cursorNotAllowed: levelId != 5 }"
              :disabled="levelId != 5" style="border-radius: 0 4px 4px 0">
              <label class="container">
                Level 5
                <input type="radio" class="cursorpointer" name="some-radios" value="5" style="margin-right: 1rem" />
              </label>

            </div>
          </b-form-radio-group>
        </div>
      </div>
      <div class="prp-info" style="
          padding-top: 4rem;
          display: flex;
          padding-bottom: 15px;
        ">
        <div class="col-lg-6 col-md-6 col-sm-6 right-border">
          <div class="module-title prp-name" style="padding-bottom: 2rem">
            Current Practice
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 padding-input">
            <label for="pillar" class="form-label select-lable lable-text">Description</label>
            <textarea type="text" class="form-control select-text text-col text-class" id="reason" disabled>
            </textarea>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 padding-input">
            <label for="pillar" class="form-label select-lable lable-text">Guideline</label>
            <textarea type="text" class="form-control select-text text-col text-class" id="reason" disabled>
            </textarea>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="module-title prp-name" style="padding-bottom: 2rem">
            Proposed Practice
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 padding-input" style="padding-left: 1rem">
            <label for="pillar" class="form-label select-lable lable-text">Description</label>
            <textarea type="text" class="form-control select-text text-col text-class" id="reason" disabled>
            </textarea>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 padding-input" style="padding-left: 1rem">
            <label for="pillar" class="form-label select-lable lable-text">Guideline</label>
            <textarea type="text" class="form-control select-text text-col text-class" id="reason" disabled>
            </textarea>
          </div>
        </div>
      </div>

      <div>

        <div class="mat-info-con add-action-footer-info border-top">
          <b-button pill class="primar-btn submit-btn" size="lg">
            Close
          </b-button>

        </div>
      </div>
    </div>
    <a id="hyLink" href=""></a>
  </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Role from "../../../common/constants/roles";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
export default {
  name: "SubmitRequest",
  components: {
    Breadcrumb,
    Loader,
  },
  data() {
    return {
      roles: Role,
      //documentName: "",
      proposedPractice: "",
      reason: "",
      id: "",
      pillars: [],
      principleNumber: 1,
      principleList: [],
      perPageSelf: 5,
      currentPageSelf: 1,
      checkConfirmation: false,
      perPageSec: 5,
      currentPageSec: 1,
      passingConfirmation: {},
      //levelId: 0,
      actionID: "",
      actionOwnerName: "",
      actionTargetDate: "",
      actionCompleteDate: "",
      actionDesc: "",
      showLoader: true,
      targetActions: "",
      committedActions: "",
      completedActions: "",
      secondPartyVisibility: true,
      selectedViewItem: null,
      fileErrorMessage: "",
      commentSelftAssessment: "",
      commentSecondAssessment: "",
      fileErrorMessageSecond: "",
      levelId: 0,
      practiceCommittedDate: "",
      practiceCompletionDate: "",
      action_item: [],
      plantAssessmentStatusUpdate: "",
      plantAssessmentStatus: "",
      plantAssessmentCompleted: "",
      regionalAssessmentStatusUpdate: "",
      regionalAssessmentStatus: "",
      practiseDetailsData: [],
      attachmentUrls: [],
      plantAttachmentURL: "",
      plantAttachmentType: "",
      regionalAttachmentURL: "",
      regionalAttachmentType: "",
      practiceDescription: "",
      guidelineDesc: "",
      targetLevel: 0,
      practiseIdData: 0,
      principleId: "",
      pillar: 1,
      index: 0,
      practiceId: 1,
      selected: 0,
      completedSteps: 75,
      totalSteps: 100,
      plantId: "",
      pillarId: 1,
      principle_action_item: [],
      levelName: "",
      principleName: "",
      // principleId:'',
      actionName: "",
      requestIdsArray: [],
      requestId: "",
      pillarName: "",
      principleArr: [],
      edit: "",
      edits: 1,
    };
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 4,
    });
  },

  //   computed: {
  //     showLeft() {
  //       return this.index > 0 ? true : false;
  //     },
  //     showRight() {
  //       return this.index < this.recommendationsList.length - 1 ? true : false;
  //     },
  //     checkPersonaForEditProBtn() {
  //       if (
  //         this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
  //         this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
  //         this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
  //         this.$oidc.userProfile.roles.includes(
  //           this.roles.REGIONALPILLARLEADER
  //         ) ||
  //         this.$oidc.userProfile.roles.includes(this.roles.PLANTMANAGER) ||
  //         this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)
  //       )
  //         return true;
  //       else return false;
  //       // if (this.$oidc.userProfile.roles.includes(this.roles.SYSTEMLEVELADMIN))
  //       //   return true;
  //       // else return false;
  //     },
  //     checkPersonaBtn() {
  //       if (
  //         this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
  //         this.$oidc.userProfile.roles.includes(
  //           this.roles.REGIONALPILLARLEADER
  //         ) ||
  //         this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)
  //       )
  //         return true;
  //       else return false;
  //     },
  //   },
  created() {
    this.edit = this.$route.params.edit;
    this.showLoader = false;
    this.plantId = this.$route.params.plantId ? this.$route.params.plantId : 1;
    this.targetLevel = this.$route.params.levelId
      ? this.$route.params.levelId
      : 3;
    this.pillarName = this.$route.params.pillarName
      ? this.$route.params.pillarName
      : "People And Enviornment Care";
    this.principleId = this.$route.params.principleId
      ? this.$route.params.principleId
      : 1;
    this.principleName = this.$route.params.principleName
      ? this.$route.params.principleName
      : "1. Pillar Mission And Objective";
    this.id = this.$route.params.id ? this.$route.params.id : 1;
    this.levelId = this.targetLevel;
    this.principleArr = this.principleName.split(". ");

    this.$store.dispatch("breadcrumb", {
      title: "View Request",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Governance",
          redirect: "/governance",
          primary: true,
        },
      ],
    });

    this.getPillarListData();
  },

  methods: {
    editRequest() {
      document.getElementById("hyLink").href =
        "/viewRequest/" + this.id + "/" + this.edits;
      document.getElementById("hyLink").click();
    },
    discardEdit() {
      this.$router.push("/governance");
    },
    // leftShift() {
    //   this.index -= 1;
    // },
    // rightShift() {
    //   this.index += 1;
    // },
    // getViewRequest() {
    //   ApiCalls.httpCallDetails(Api.GETVIEWREQUEST + this.requestId, "get").then(
    //     (data) => {
    //       console.log("get view data ", data);
    //       document.getElementById("proposedPractice").value = data.data
    //         .proposedPractice
    //         ? data.data.proposedPractice
    //         : "";
    //       document.getElementById("reason").value = data.data.reasonForChange
    //         ? data.data.reasonForChange
    //         : "";
    //       //this.changeType = data.data.changeId;
    //       document.getElementById("changeT").value = data.data.changeType
    //         ? data.data.changeType
    //         : "";
    //       // document.getElementById("submitBy").value = data.data.submittedBy?data.data.submittedBy:"";
    //     }
    //   );
    // },
    getRecommendations() {
      console.log("this.requestIdsArray ", this.requestIdsArray);
      let req = {
        requestIds: this.requestIdsArray,
      };

      console.log(req);
      this.showLoader = true;

      ApiCalls.httpCallDetails(Api.GETRECOMMENDATIONS, "post", req).then(
        (dataJSON) => {
          console.log("res", dataJSON);
          this.recommendationsList = dataJSON.data;
          this.showLoader = false;
        }
      );
    },
    practiseDetails(selectObject) {
      this.showLoader = true;
      let value = selectObject;
      ApiCalls.httpCallDetails(
        Api.PRACTISEDETAILS + value + "/" + this.plantId,
        // Api.PRACTISEDETAILS + value + "/" + 1,
        "get"
      ).then((dataJSON) => {
        console.log("PRACTISEDETAILS", dataJSON);

        this.practiseDetailsData = dataJSON.data.practiceList;

        setTimeout(() => {
          this.handleRadioChange(this.targetLevel);
          this.showLoader = false;
        }, 300);
      });
    },
    getPlantPillarDetails() {
      ApiCalls.httpCallDetails(
        //Api.PLANTPILLARDETAILS + 1,
        Api.PLANTPILLARDETAILS + this.plantId,
        "get"
      ).then((data) => {
        this.apiCount++;
        this.pillarDetails = data.data;
        console.log("this.pillarDetails ", this.pillarDetails);
        if (this.apiCount == 2) {
          this.showLoader = false;
          this.apiCount = 0;
        }
      });
    },
    handleRadioChange(id) {
      for (let i = 0; i <= this.targetLevel; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.add("live-level");
      }
      for (let i = 0; i <= 5; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.remove("active-level");
        radio.disabled = true;
      }

      this.guidelineDesc =
        this.practiseDetailsData[parseInt(id)] &&
          this.practiseDetailsData[parseInt(id)].guideline
          ? this.practiseDetailsData[parseInt(id)].guideline
          : "";
      this.attachmentUrls =
        this.practiseDetailsData[parseInt(id)].attachmentUrls;
      console.log(" this.attachmentUrls.length  " + this.attachmentUrls.length);
      this.practiceDescription =
        this.practiseDetailsData[parseInt(id)] &&
          this.practiseDetailsData[parseInt(id)].practiceDescription
          ? this.practiseDetailsData[parseInt(id)].practiceDescription
          : "";

      let radioElement = document.getElementById("Level" + id);
      radioElement.classList.add("active-level");
      this.levelId = this.practiseDetailsData[parseInt(id)].levelId;
      this.practiceId = this.practiseDetailsData[parseInt(id)].practiceId;
      console.log("targetlevel " + this.levelId);
    },
    getPillarListData() {
      let url = Api.PILLARLIST + this.pillarId;
      console.log(url);
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.pillars = data.data;
        console.log(" this.pillars ", this.pillars);
      });
      this.getPrincipleData();
    },

    async getPrincipleData() {
      this.showLoader = true;
      //   ApiCalls.httpCallDetails(Api.PRINCIPLELIST + this.pillarId, "get").then(
      ApiCalls.httpCallDetails(Api.PRINCIPLELIST + 1, "get").then(
        (dataJSON) => {
          console.log("PRINCIPLEDATA", dataJSON);
          this.principleList = [];
          let data = dataJSON.data;
          this.principleList = data;
          this.practiseDetails(this.principleId);

          this.showLoader = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.li-doc-class {
  margin-bottom: 8px;
  color: #274ba7;
  font-family: "BarlowR", sans-serif;
  list-style: none;
  line-height: 1.9166666666666667rem;
  font-size: 1.1666666666666667rem;
}

.select-text {
  font-size: 1.166rem !important;
  font-family: "BarlowM", sans-serif !important;
}

.cursorNotAllowed {
  cursor: not-allowed;
  opacity: 0.3;
}

textarea {
  resize: none;
}

.principle-select {
  height: 4.4rem;
  border-color: #313131;
  background: #ecedf4;
}

.gray-box-outer {
  background-color: #ecedf4;
  margin: 0 2rem;
  padding: 1rem 2rem;
  height: 38rem;
  border-radius: 6px;
}

.gray-box-left {
  flex: 0;
}

.gray-box-inner {
  flex: 1;
}

.gray-box-right {
  flex: 0;
}

.gray-box-inner-row1 {
  padding: 1rem 2rem;
  width: 65%;
  display: flex;
  justify-content: space-between;
}

.gray-box-inner-row2 {
  padding: 1rem 2rem;
}

.gray-box-center {
  display: flex;
  align-items: center;
}

.gray-box-title {
  font-size: 1.5rem;
  font-family: "BarlowM", sans-serif;
}

.gray-box-label {
  background: #ecedf4;
}

.proposed-rec {
  background: #f9f9f9;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
}

.level-border {
  display: flex;
  border: 1px solid #dedbdb;
  /* padding-top: 1em;
  padding-bottom: 1em; */
  border-radius: 6px;
}

.add-action-footer-info {
  padding-top: 1rem;
  background: #fff;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.submit-btn {
  height: 3.3rem;
  width: 6.166666666666666rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #274ba7;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.req {
  font-size: 1rem;
  color: red;
}

.text-col {
  height: 7rem;
  resize: none;
  overflow: hidden;
}

.padding-input {
  /* padding-top: 2rem; */
  padding-left: 0rem;
  padding-bottom: 2rem;
}

.right-border {
  border-right: 1px solid #bbb8b8;
}

.text-class {
  background-color: #f8f9fa !important;
  border: 1px solid #d5d2d2;
  border: 1px solid #313131;
  color: #313131;
  font-size: 1.5rem;
  font-family: 'BarlowM', sans-serif;
  /* padding-bottom: 2rem; */
}

.text-class1 {
  background-color: #fff;
  border: 1px solid #d5d2d2;
  border: 1px solid #313131;
  color: #313131;
  /* padding-bottom: 2rem; */
}

.marginRight16Percentage {
  margin-right: 5%;
}

.col-5 {
  max-width: 55%;
}

.width100 {
  width: 100%;
}

.marginTop-minus-2 {
  margin-top: -2% !important;
}

.marginBottom-2 {
  margin-bottom: 2% !important;
}

.lable-text {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #fff;
  margin: -1.2rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}

.lable-text1 {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #ecedf4;
  margin: -1.2rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}

.calender-input {
  height: 52px;
  width: 59.25rem;
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

@media only screen and (max-width: 1365px) {
  .calender-input {
    height: 52px;
    width: 39.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1380px) {
  .calender-input {
    height: 52px;
    width: 43.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1381px) {
  .calender-input {
    height: 52px;
    width: 55.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
  .calender-input {
    height: 52px;
    width: 64.75rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1921px) {
  .calender-input {
    height: 52px;
    width: 68.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

.other-block {
  /* display: grid;
  justify-content: end; */
  margin-top: 2rem;
}

div.fileinputs {
  position: relative;
  border: 1px solid #313131;
  border-radius: 4px;
}

div.fakefile {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.fakefileinput {
  height: 100%;
  width: 80%;
  border: transparent;
  border-right: 1px solid #313131;
  color: #313131;
  font: 1.166rem "BarlowR", sans-serif;
  padding: 5px;
  margin: 2px;
}

.fakefilespan {
  text-align: center;
  width: 20%;
  font: 1.166rem "BarlowSb", sans-serif;
  color: #274ba7;
}

input.file {
  position: relative;
  text-align: right;
  /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
  opacity: 0;
  z-index: 2;
}

.container {
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide the default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

#editButton:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.top-card {
  width: 7rem;
}

.plant-card {
  background: #fffce3;
  /* // height: 54px; */
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 8px;
  border-radius: 7px;
}

.action_label {
  font-family: "BarlowM", sans-serif;
  line-height: 1.5rem;
  font-size: 1.3333333333333333rem;
}

.btn-action {
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.progress-con {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.per-text {
  color: #0f7222;
  font-weight: 600;
}

.asmt-con {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  padding-bottom: 1em;
}

.asmt-info {
  border-bottom: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asmt-padding {
  margin-top: 1em;
  margin-bottom: 1em;
}

.asmt-footer-info {
  border-top: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
}

.principle-dd {
  width: 30%;
  padding: 2rem 2rem;
  padding-bottom: 0;
}

.recommend-d {
  width: 30%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.principal-selecct {
  height: 54px;
  border-color: #313131;
}

.level-con {
  /* padding: 1em; */
  margin-top: 1em;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
}

.action-desc {
  border: #313131 1px solid;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  color: #313131;
  resize: none;
}

.level-info {
  width: 17%;
  text-align: center;
  height: 64px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-level {
  background: #eff3ff;
}

.cursorpointer {
  cursor: pointer;
}

.level-br {
  border-right: 1px solid #dedbdb;
}

.active-level {
  background: #00428f !important;
  opacity: 1;
  color: #fff;
  /* background: #006400;6577AC */
}

.active-level:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #00428f;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.prp-name {
  margin-top: 3px;
}

.margin-top-1em {
  margin-top: 1rem;
}

.behavoir-des {
  margin-top: 3px;
  /* margin-bottom: 5px; */
}

.attch-con {
  padding-left: 2em;
  padding-right: 2em;
}

.attch-col {
  border-radius: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 5px !important;
  color: #274ba7;
  list-style: none;
}

.attch-row {
  padding-left: 1em;
  padding-right: 2em;
  margin-top: 5px;
}

.attch-icon {
  float: right;
}

.review-con {
  padding-left: 2em;
  padding-right: 2em;
}

.review-rox {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
}

.review-col {
  border: 2px solid #e2dfdf;
  border-radius: 6px;
  padding: 1em;
}

.pm-rw-con {
  margin-right: 71px;
}

.review-row {
  padding: 1em;
}

.review-type-col {
  text-align: center;
  background: #f0f7ff;
  border-radius: 6px;
  padding: 1em;
  margin-right: 10px;
}

.rw-act-con {
  text-align: right;
  margin-top: 10px;
}

.cancel-btn {
  border: 0px !important;
}

.cancel-btn:hover {
  background: #fff;
  color: #274ba7;
}


.cancel-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.save-btn {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.save-btn:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.cancel-btn:active {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.save-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.shortHeight {
  height: 12rem;
}

.pagination-con {
  font-size: 0.875em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  margin: 0;
}

@media only screen and (max-width: 1100px) {
  .active-level:after {
    left: -27% !important;
  }
}

@media only screen and (max-width: 875px) {
  .active-level:after {
    left: -30% !important;
  }
}

@media only screen and (max-width: 575px) {
  .level-con {
    overflow-x: auto;
  }

  .level-info {
    padding: 1em;
    width: 41%;
  }

  .principle-dd {
    width: 50%;
  }

  .active-level:after {
    left: -40% !important;
  }
}

@media only screen and (min-width: 768px) {
  active-level:after {
    top: 71.5%;
    left: 61%;
    margin-left: -45px;
  }

  .review-type-col {
    max-width: 30.333333% !important;
  }

  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .asmt-info {
    display: block !important;
  }

  .asmt-name {
    width: 100% !important;
  }

  .progress-con {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (min-width: 992px) {
  .review-rox .col-6 {
    max-width: 47% !important;
  }
}

@media only screen and (max-width: 992px) {
  .pp-rw {
    margin-top: 1em !important;
  }

  .principle-dd {
    width: 40% !important;
  }

  .pagination-align {
    justify-content: left !important;
  }
}

@media only screen and (max-width: 575px) {
  .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 10px !important;
  }

  .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .principle-dd {
    width: 100% !important;
  }
}

.review-box:focus~label {
  color: #274ba7;
  top: -0.8rem;
  left: 1rem;
  transition: all 0.125s ease;
  background: #fff;
  padding: 0 4px;
}

.review-box:focus {
  border: 2px solid #274ba7;
}

.review-box:focus-visible {
  border: 2px solid #274ba7;
}

.styled-input {
  width: 33.3333%;
  position: relative;
}

.styled-input label {
  padding: 1.6rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 100%;
}


.review-box:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}


.review-box {
  width: 100%;
  border: 1px solid #d2cbcb;
  border-radius: 6px;
  height: 5em;
  resize: none;
  padding: 1rem 1rem;
  font-size: 1rem;
  font-family: "BarlowR", sans-serif;
}


.review-com {
  margin-top: 10px;
}

.add-action {
  text-align: right;
}

.action-item-con {
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
}

.back-btn {
  background: #274ba7;
  border-color: #274ba7;
  padding: 0.6rem 1.2rem;
}

.back-btn :hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.back-btn :active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn {
  background: #274ba7;
  border-color: #274ba7;
}

.add-action-btn:hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.act-tal-con {
  margin-top: 1em;
}

.back-btn-con {
  text-align: right;
  margin-right: 15px;
}

.take-assessment-page-heading-tab-bottom-padding {
  padding-bottom: 1rem;
}

.take-assessment-page-heading-tab-font-weight {
  font-size: 1.083rem;
  font-family: "BarlowM", sans-serif;
}

.status-con {
  display: flex;
}

.status-col-1 {
  width: 50%;
}

.status-col-2 {
  width: 50%;
  text-align: right;
}

.status-btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.status-btn-2 {
  margin-left: 1rem;
}

.file-con {
  width: 100%;
}

#errorForComment,
#errorForUploadFile {
  color: red;
  font-family: "BarlowR", sans-serif;
}

#edit-li:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

#edit-li {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

.backBtnBlock {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 992px) and (max-width: 1240px) {
  .status-con[data-v-0d8de0d6] {
    display: block !important;
  }

  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 665px) {
  .status-con[data-v-0d8de0d6] {
    display: block !important;
  }

  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 388px) {
  .status-btn-2 {
    margin-left: 0rem;
    margin-top: 1rem !important;
  }
}

.comment-lable {
  color: #274ba7;
  top: -0.8rem !important;
  left: 1rem !important;
  background: #fff;
  padding: 0 4px !important;
}
</style>